import CaraBayarRepository from '@/repositories/master/cara-bayar-repository'
import BankKprRepository from '@/repositories/master/bank-kpr-repository'
import SystemParameterRepository from '@/repositories/general/system-parameter-repository'
import constant from '@/constant/constant'
import formValidation from '@/views/pages/marketing/booking/mixins/tab-angsuran/formValidation'
import moment from 'moment'
import _ from 'lodash'

export default {
  mounted () {
    this.getAllCaraBayar()
    this.getAllCaraBayarBf()
    this.getAllBankKpr()
    this.getAllJenisPph()
  },
  watch: {
    grandTotal () {
      this.adjustNominalForLastTermin()
      this.updateAllTerminItemCalculation()
    },
    'data.diskon_jml' () {
      this.adjustNominalForLastTermin()
      this.updateAllTerminItemCalculation()
    },
    'data.ppn_jml' () {
      this.adjustNominalForLastTermin()
      this.updateAllTerminItemCalculation()
    },
    'data.pph_jml' () {
      this.adjustNominalForLastTermin()
      this.updateAllTerminItemCalculation()
    },
    'data.booking_fee' (newVal) {
      const indexOfTerminBookingFee = _.findIndex(this.data.angsurans, item => item.tipe_angsuran === constant.TIPE_ANGSURAN.BOOKING_FEE)
      if (indexOfTerminBookingFee !== -1) {
        this.data.angsurans[indexOfTerminBookingFee].jml_nominal = newVal
        this.updateAllTerminItemCalculation()
      }
    },
    'data.booking_fee_status' () {
      this.adjustNominalForLastTermin()
      this.updateAllTerminItemCalculation()
    }
  },
  computed: {
    ...formValidation,
    isCanGenerateTermins () {
      return this.data.angsurans.length < 1 &&
        this.data.termin_angsuran &&
        this.data.angsuran_start_due
    },
    isBookingFeeIncludeDiHargaJual () {
      return this.data.booking_fee_status === 'INCLUDE'
    },
    totalHargaUnit () {
      return (
        (parseFloat(this.data.unit.harga_standar) || 0) +
        (parseFloat(this.data.unit.harga_luas) || 0) +
        (parseFloat(this.data.unit.harga_tinggi) || 0) +
        (parseFloat(this.data.unit.harga_view) || 0) +
        (parseFloat(this.data.unit.harga_mutu) || 0) +
        (parseFloat(this.data.unit.biaya_adm) || 0) +
        (parseFloat(this.data.unit.biaya_notaris) || 0)
      )
    },
    dpp () {
      const dppBookingFee = this.isBookingFeeIncludeDiHargaJual ? 0 : (this.totalBookingFee / ((100 + (this.data.ppn_persen || 0)) / 100))
      return this.totalHargaUnit - (this.data.diskon_jml || 0) + dppBookingFee
    },
    totalHargaJual () {
      const dpp = this.dpp
      const ppn = this.data.ppn_jml || 0
      return dpp + ppn
    },
    totalSisaHargaJual () {
      if (this.totalHargaJual > 0) {
        return this.isBookingFeeIncludeDiHargaJual ? (this.totalHargaJual - this.totalSisaBookingFee) : (this.totalHargaJual)
      } else {
        return 0
      }
    },
    totalBookingFee () {
      return (this.data.booking_fee || 0)
    },
    totalSisaBookingFee () {
      return this.totalBookingFee
    },
    totalUangMuka () {
      return (this.data.dp_jml || 0)
    },
    totalSisaUangMuka () {
      return this.totalUangMuka
    },
    totalSisaAngsuran () {
      const totalBookingFee = this.isBookingFeeIncludeDiHargaJual ? 0 : this.totalBookingFee
      return this.totalSisaHargaJual - this.totalSisaUangMuka - totalBookingFee
    },
    grandTotal () {
      return this.totalSisaUangMuka + this.totalSisaAngsuran + this.totalSisaBookingFee
    },
    footerTermin () {
      return {
        totalPercent: _.sumBy(this.data.angsurans, item => item.persen),
        totalDpp: _.sumBy(this.data.angsurans, item => item.dpp),
        totalNominal: _.sumBy(this.data.angsurans, item => item.jml_nominal),
        totalPpn: _.sumBy(this.data.angsurans, item => item.jml_ppn),
        totalPph: _.sumBy(this.data.angsurans, item => item.jml_pph)
      }
    }
  },
  data () {
    return {
      data: {
        pph_persen: null,
        pph_jml: null,
        pph_nama: null,
        pph_key: null,
        ppn_jenis: 'TANPA PPN',
        ppn_key: 'ppn_persen'
      },
      caraBayars: [],
      caraBayarBookingFees: [],
      bankKprs: [],
      boofeeStatuses: [
        {
          kode: 'INCLUDE',
          nama: 'Include di Harga Jual'
        },
        {
          kode: 'EXCLUDE',
          nama: 'Exclude di Harga Jual'
        }
      ],
      listPphJenis: []
    }
  },
  methods: {
    clearTermins () {
      this.data.angsurans = []
    },

    refreshTermins () {
      this.clearTermins()
      this.generateTermins()
    },

    generateTermins () {
      // generate termin
      const angsurans = [
        ...this.generateTerminBookingFee(),
        ...this.generateTerminUangMuka(),
        ...this.generateTerminAngsuran()
      ]

      // cek selisih pembulatan
      const grandTotalTerminGenerated = parseFloat((_.sumBy(angsurans, item => parseFloat(item.jml_nominal.toFixed(2)))).toFixed(2))
      const grandTotal = parseFloat(this.grandTotal.toFixed(2))
      if (grandTotalTerminGenerated !== grandTotal) {
        const lastIndex = angsurans.length - 1
        angsurans[lastIndex].jml_nominal += (grandTotal - grandTotalTerminGenerated)
      }

      // set termin
      this.data.angsurans = angsurans
    },

    generateTerminBookingFee () {
      const numOfTermin = this.totalSisaBookingFee > 0 ? 1 : 0
      const dueDate = this.data.booking_fee_due
      const total = this.totalSisaBookingFee
      const nominalPerTermin = (total / numOfTermin)
      const persen = (nominalPerTermin / this.grandTotal) * 100
      const dppPerTermin = (nominalPerTermin / ((100 + this.data.ppn_persen) / 100))
      const termins = []

      for (let i = 0; i < numOfTermin; i++) {
        termins.push({
          tipe_angsuran: constant.TIPE_ANGSURAN.BOOKING_FEE,
          no_termin: 0,
          tgl_due: dueDate,
          persen: persen,
          jml_nominal: nominalPerTermin,
          dpp: dppPerTermin,
          jml_ppn: (this.data.ppn_persen / 100 * dppPerTermin),
          jml_pph: (this.data.pph_persen / 100 * dppPerTermin),
          keterangan: null
        })
      }

      return termins
    },

    generateTerminUangMuka () {
      const numOfTermin = this.data.termin_dp
      const dueDate = null
      const total = this.totalSisaUangMuka
      const nominalPerTermin = (total / numOfTermin)
      const persen = (nominalPerTermin / this.grandTotal) * 100
      const dppPerTermin = (nominalPerTermin / ((100 + this.data.ppn_persen) / 100))
      const termins = []

      for (let i = 0; i < numOfTermin; i++) {
        termins.push({
          tipe_angsuran: constant.TIPE_ANGSURAN.UANG_MUKA,
          no_termin: i + 1,
          tgl_due: dueDate,
          persen: persen,
          jml_nominal: nominalPerTermin,
          dpp: dppPerTermin,
          jml_ppn: (this.data.ppn_persen / 100 * dppPerTermin),
          jml_pph: (this.data.pph_persen / 100 * dppPerTermin),
          keterangan: null
        })
      }

      return termins
    },

    generateTerminAngsuran () {
      const numOfTermin = this.data.termin_angsuran
      const dueDate = this.data.angsuran_start_due
      const total = this.totalSisaAngsuran
      const nominalPerTermin = (total / numOfTermin)
      const persen = (nominalPerTermin / this.grandTotal) * 100
      const dppPerTermin = (nominalPerTermin / ((100 + this.data.ppn_persen) / 100))
      const termins = []

      for (let i = 0; i < numOfTermin; i++) {
        termins.push({
          tipe_angsuran: constant.TIPE_ANGSURAN.ANGSURAN,
          no_termin: (parseInt(this.data.termin_dp) || 0) + (i + 1),
          tgl_due: dueDate ? moment(dueDate).add(i, 'months').format('YYYY-MM-DD') : null,
          persen: persen,
          jml_nominal: nominalPerTermin,
          dpp: dppPerTermin,
          jml_ppn: (this.data.ppn_persen / 100 * dppPerTermin),
          jml_pph: (this.data.pph_persen / 100 * dppPerTermin),
          keterangan: null
        })
      }

      return termins
    },

    updateAllTerminItemCalculation: _.debounce(function (value) {
      this.data.angsurans.forEach((item, index) => {
        this.updateTerminItemCalculation(index)
      })
    }, 500),

    updateTerminItemCalculation (index) {
      const grandTotal = this.grandTotal
      const dpp = grandTotal / ((100 + this.data.ppn_persen) / 100)
      const ppn = dpp * ((this.data.ppn_persen || 0) / 100)
      const pph = dpp * ((this.data.pph_persen || 0) / 100)

      const newNominal = this.data.angsurans[index].jml_nominal
      const newPersen = (newNominal / this.grandTotal) * 100
      this.data.angsurans[index].persen = newPersen
      this.data.angsurans[index].dpp = dpp * (newPersen / 100)
      this.data.angsurans[index].jml_ppn = ppn * (newPersen / 100)
      this.data.angsurans[index].jml_pph = pph * (newPersen / 100)
    },

    adjustNominalForLastTermin () {
      if (this.data.angsurans.length > 0) {
        const lastIndex = this.data.angsurans.length - 1
        const balance = this.grandTotal - this.footerTermin.totalNominal
        this.data.angsurans[lastIndex].jml_nominal += balance
      }
    },

    getAllJenisPph () {
      const params = { group: 'PPH' }
      SystemParameterRepository.getAll(params)
        .then(response => {
          this.listPphJenis = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    onChangePphJenis (pphKey) {
      const data = _.find(this.listPphJenis, item => item.key === pphKey)
      this.data.pph_persen = data.value
      this.data.pph_jml = this.dpp * (data.value / 100)
      this.data.pph_nama = data.alias
      this.data.pph_key = data.key
    },

    getAllCaraBayar () {
      const params = {}
      params.filter = JSON.stringify({ group1: 'KONTRAK' })

      CaraBayarRepository.getAll(params)
        .then(response => {
          this.caraBayars = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getAllCaraBayarBf () {
      const params = {}
      params.filter = JSON.stringify({ group1: 'BOOKING FEE' })

      CaraBayarRepository.getAll(params)
        .then(response => {
          this.caraBayarBookingFees = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getAllBankKpr () {
      BankKprRepository.getAll()
        .then(response => {
          this.bankKprs = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getColorOfTipeAngsuran (tipe) {
      if (tipe === constant.TIPE_ANGSURAN.BOOKING_FEE) {
        return 'danger'
      } else if (tipe === constant.TIPE_ANGSURAN.UANG_MUKA) {
        return 'green'
      } else if (tipe === constant.TIPE_ANGSURAN.ANGSURAN) {
        return 'primary'
      } else {
        return 'black'
      }
    },

    idr (value, decimal = 2) {
      return this.$options.filters.idr(value, decimal)
    }
  }
}
